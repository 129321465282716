@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

@layer base {
    body {
      @apply font-poppins;
    }
    input {
      @apply rounded-r-full px-2 outline-none w-full;
    }
    .announcement {
      @apply bg-white rounded-3xl py-4 px-4 shadow-md;
    }
  }

body {
    background: rgb(18,18,18);
    background: linear-gradient(180deg, rgba(0, 0, 0,1) 0%, rgba(27,27,27,1) 100%);
    /*background: linear-gradient(180deg, rgba(18,18,18,1) 0%, rgba(36,36,36,1) 100%);
    background-color: #cfcfcf;
    background-image: url('https://www.toptal.com/designers/subtlepatterns/uploads/memphis-mini-dark.png');*/

   

}


@media screen and (max-width:1279px)
{
    body {
        background: #181818;
    }
}

::selection
{
    background-color: #fff;
    color: #181818;
}

/* Scrollbar */

*::-webkit-scrollbar
{
	width: 10px;
}

*::-webkit-scrollbar-thumb
{
	background-color: #d1d1d1;
    border-radius: 999px;
}


.add-forms textarea::-webkit-scrollbar
{
	width: 10px;
}

.add-forms textarea::-webkit-scrollbar-thumb
{
	background-color: #d1d1d1;
    border-radius: 999px;
}